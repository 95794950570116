import React, { useState } from 'react';
import { owner, renter } from '../../resources/index';
import { OWNER_BENEFITS, RENTER_BENEFITS, TANGO_FIGURES, CARD_ITEMS } from './options';
import Carousel from 'react-multi-carousel';
import intl from 'react-intl-universal';
import { Link } from '@material-ui/core';

const AboutUsComponent = () => {
    const [hover, setHover] = useState(false);
    const [hover1, setHover1] = useState(false);

    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024,
            },
            items: 1,
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 300,
            },
            items: 1,
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464,
            },
            items: 1,
        },
    };
    return (
        <div>
            <div className='about-us-container container-home pd-1'>
                <div className='template-line-home my-4' style={{ backgroundColor: `#A8123E` }} />
                <h1>{intl.get('TANGO_REAL_ESTATE_PORTAL')}</h1>
                <p className='about-us-paragraph p-3'>{intl.get('TANGO_EXPLANATION')}</p>
                <section className='d-none d-sm-block'>
                    <div className='row bg-white about-us-card my-4'>
                        {CARD_ITEMS.map((item, index) => (
                            <div key={index} className={`col-4 ${index === 1 ? 'middle-card' : 'bg-transparent'} py-4`}>
                                <img className='my-4' src={item.icon} height='60' alt='card-icon' />
                                <p className='px-3'>{intl.get(item.message)}</p>
                            </div>
                        ))}
                    </div>
                </section>
                <section className='d-block d-sm-none'>
                    <div className='row bg-white about-us-card my-4'>
                        {CARD_ITEMS.map((item, index) => (
                            <div key={index} className={`${index === 1 ? 'middle-card' : 'bg-transparent'}`}>
                                <img className='my-4' src={item.icon} height='50' alt='card-icon' />
                                <p className='px-3'>{intl.get(item.message)}</p>
                            </div>
                        ))}
                    </div>
                </section>

                <div className='template-line-home my-4' style={{ backgroundColor: `#A8123E` }} />
                <h2 className='p-3'>{intl.getHTML('TANGO_IS_GREYSTAR')}</h2>
                <section className='d-none d-md-block'>
                    <div className='row about-us-card bg-white my-5'>
                        {TANGO_FIGURES.map((figure, index) => (
                            <div
                                key={index}
                                className={`col-3 bg-transparent ${
                                    index > 0 && 'border-secondary-color-left'
                                } my-3 py-4`}
                            >
                                <h2 className='card-title text-color-secondary font-weight-bold'>{figure.number}</h2>
                                <h3>{intl.get(figure.message)}</h3>
                            </div>
                        ))}
                    </div>
                </section>
                <section className='d-block d-md-none'>
                    <div className='row about-us-card bg-white my-5'>
                        {TANGO_FIGURES.map((figure, index) => (
                            <div
                                key={index}
                                className={`col-6 bg-transparent ${
                                    index === 1 || index === 3 ? 'border-secondary-color-left' : ''
                                }  ${index < 2 && 'secondary-border-bottom'} my-3 py-4`}
                            >
                                <h2 className='card-title text-color-secondary font-weight-bold'>{figure.number}</h2>
                                <h3>{intl.get(figure.message)}</h3>
                            </div>
                        ))}
                    </div>
                </section>
            </div>

            <section className='d-none d-md-block tango-red-belt p-3 w-100'>
                <div className='template-line-home my-4' style={{ backgroundColor: `#FFFF` }} />
                <h2>{intl.get('SERVICES_WE_OFFER')}</h2>
                <div className='d-flex my-5'>
                    <div className='thin-column mx-5'>
                        <div>
                            <img src={owner} height='100' alt='owner-icon' />
                        </div>
                        <p>{intl.get('IF_OWNER')}</p>
                        <div className='pt-5 mt-2'>
                            <img src={renter} height='100' alt='owner-icon' />
                        </div>
                        <p>{intl.get('IF_RENTER')}</p>
                    </div>
                    <div className='large-column border-bold-white-left'>
                        <div className='row'>
                            {OWNER_BENEFITS.map((owner, index) => (
                                <div key={index} className='col-3'>
                                    <div>
                                        <img className='mb-3' src={owner.icon} height='60' alt='owner-icon' />
                                    </div>
                                    <p>{intl.get(owner.description)}</p>
                                </div>
                            ))}
                        </div>
                        <div className='row mt-3'>
                            {RENTER_BENEFITS.map((renter, index) => (
                                <div key={index} className='col-3'>
                                    <div>
                                        <img className='mb-3' src={renter.icon} height='60' alt='owner-icon' />
                                    </div>
                                    <p>{intl.get(renter.description)}</p>
                                </div>
                            ))}
                        </div>
                        <div className='ml-3 mt-4 text-left d-flex flex-column'>
                            <small>{intl.get('SAFEGUARD_GUARANTEE_INFO')}</small>
                            <small>*{intl.get('SECURITY_DEPOSIT_INFO')}</small>
                        </div>
                    </div>
                </div>
            </section>
            <section className='d-block d-md-none tango-red-belt p-3'>
                <div className='template-line-home mb-2' style={{ backgroundColor: `#FFFF` }} />
                <h2 className='mb-3'>{intl.get('SERVICES_WE_OFFER')}</h2>
                <Carousel
                    containerClass='container-with-dots'
                    showDots
                    deviceType='desktop'
                    infinite={false}
                    itemClass='image-item'
                    keyBoardControl={true}
                    ssr
                    sliderClass=''
                    swipeable
                    responsive={responsive}
                    arrows={false}
                >
                    <div className='pb-3'>
                        <div>
                            <img src={owner} height='55' alt='owner-icon' />
                        </div>
                        <h3>{intl.get('IF_OWNER')}</h3>
                        <div className='row my-4'>
                            {OWNER_BENEFITS.map((owner, index) => (
                                <div key={index} className='col-6 p-0'>
                                    <div>
                                        <img className='mb-3' src={owner.icon} height='40' alt='owner-icon' />
                                    </div>
                                    <p>{intl.get(owner.description)}</p>
                                </div>
                            ))}
                        </div>
                        <div className='text-left'>
                            <small>{intl.get('SAFEGUARD_GUARANTEE_INFO')}</small>
                        </div>
                    </div>
                    <div className='pb-3'>
                        <div>
                            <img src={renter} height='55' alt='owner-icon' />
                        </div>
                        <h3>{intl.get('IF_RENTER')}</h3>
                        <div className='row my-4'>
                            {RENTER_BENEFITS.map((renter, index) => (
                                <div key={index} className='col-6 p-0'>
                                    <div>
                                        <img className='mb-3' src={renter.icon} height='40' alt='owner-icon' />
                                    </div>
                                    <p>{intl.get(renter.description)}</p>
                                </div>
                            ))}
                        </div>
                        <div className='text-left'>
                            <small>*{intl.get('SECURITY_DEPOSIT_INFO')}</small>
                        </div>
                    </div>
                </Carousel>
            </section>
            <div className='container-home pd-1'>
                <div className='template-line-home mb-3 mt-5' style={{ backgroundColor: `#A8123E` }} />
                <p className='about-us-paragraph'>{intl.getHTML('STILL_DOUBTS')}</p>
                <p className='about-us-paragraph'>{intl.get('SEE_VIDEO')}</p>
                <div className='embed-responsive embed-responsive-16by9 mt-5'>
                    <iframe
                        title='Tango Video'
                        className='embed-responsive-item'
                        src='https://www.youtube.com/embed/RSIyqPRXLsU'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                    ></iframe>
                </div>
                <div className='template-line-home mb-3 mt-5' style={{ backgroundColor: `#A8123E` }} />
                <h2>{intl.get('WHAT_WOULD_YOU_LIKE')}</h2>
                <section className='row mt-5 d-flex justify-content-center'>
                    <div className='col-sm-10 col-lg-6 mb-3'>
                        <Link href={process.env.REACT_APP_OWNER}>
                            <button
                                onMouseOver={() => setHover(true)}
                                onMouseOut={() => setHover(false)}
                                onClick={() => setHover(true)}
                                className={` ${hover ? 'button-secondary' : 'button-change-info'} w-100`}
                            >
                                {intl.get('PUT_MY_PROPERTY')}
                            </button>
                        </Link>
                    </div>
                    <div className='col-sm-10 col-lg-6'>
                        <Link href={process.env.REACT_APP_RENTER}>
                            <button
                                onMouseOver={() => setHover1(true)}
                                onMouseOut={() => setHover1(false)}
                                onClick={() => setHover(true)}
                                className={` ${hover1 ? 'button-secondary' : 'button-change-info'} w-100`}
                            >
                                {intl.get('RENT_A_PROPERTY')}
                            </button>
                        </Link>
                    </div>
                </section>
            </div>
        </div>
    );
};
export default AboutUsComponent;
