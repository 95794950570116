import { filtersHelper, dataHelper } from "../../helpers/configViewsHelper"

const restored = {
  filters: filtersHelper,
  type_table: 'restored',
  label: 'RESTITUTIONS_SEARCH_LABEL',
  placeholder_text: 'UNIT_ADDRESS_PLACEHOLDER_TEXT',
  table: {
    data: dataHelper,
    actions: [{ type: 'watch', name: 'INVENTORY' }],
  },
}

export default restored
