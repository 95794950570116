import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setTemplateChild, getOffersRented } from '../../tango-react-base/reduxActions'
import { AgentReduxStates } from '../../tango'
import Table from '../../tango-react-base/components/tables/staticTable'
import configDelivered from './configDelivered'
import configPendingDelivered from './configPendingDeliver'
import Glossary from '../../tango-react-base/components/glossary'
import STATE from '../../tango-react-base/helpers/glossaryItems/index'
import { SearchComponent, CircularLoading } from '../../tango-react-base/components'

type TablesProps = {
  onSetTemplateChild: (child: Object) => void
  offersRented: any
  offersRentedPending: any
  fetchingOffersR: string
  fetchingOffersRP: string
  onGetOffersRented: any
}

const Tables = ({
  onSetTemplateChild,
  offersRented,
  offersRentedPending,
  fetchingOffersR,
  fetchingOffersRP,
  onGetOffersRented
}: TablesProps) => {
  const history = useHistory()

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>
            {intl.get('DELIVERS')}
          </h1>
        </>,
      )
      onGetOffersRented('pending')
      onGetOffersRented('approved')
    }
  }, [onSetTemplateChild])

  const [filteredDataDelivered, setFilteredDataDelivered] = useState('')
  const [filteredDataPendingDelivered, setFilteredDataPendingDelivered] = useState('')
  const [searchFieldDelivered, setSearchFieldDelivered] = useState('')
  const [searchFieldPendingDelivered, setSearchFieldPendingDelivered] = useState('')

  useEffect(() => {
    if (fetchingOffersR === 'FETCHED_OFFERS_RENTED') {
      setFilteredDataDelivered(offersRented)
    }
  }, [fetchingOffersR])

  useEffect(() => {
    if (fetchingOffersRP === 'FETCHED_OFFERS_RENTED_PENDING') {
      setFilteredDataPendingDelivered(offersRentedPending)
    }
  }, [fetchingOffersRP])

  return (
    <div className='container-home pd-1'>
      {filteredDataPendingDelivered &&
        <>
          <SearchComponent
            config={configPendingDelivered}
            searchField={searchFieldPendingDelivered}
            setSearchField={setSearchFieldPendingDelivered}
            onGetData={onGetOffersRented}
            parameterOnGetData='pending'
          />
          <div className='template-line' />
          <h2 className='new-subtitle'>
            {intl.get('PENDING_DELIVER')}
          </h2>
          {fetchingOffersRP === 'FETCHED_OFFERS_RENTED_PENDING' ?
            <Table
              parameters={filteredDataPendingDelivered}
              config={configPendingDelivered}
            />
            :
            <CircularLoading />
          }
        </>
      }
      {filteredDataDelivered &&
        <>
          <SearchComponent
            config={configDelivered}
            searchField={searchFieldDelivered}
            setSearchField={setSearchFieldDelivered}
            onGetData={onGetOffersRented}
            parameterOnGetData='approved'
          />
          <div className='template-line' />
          <h2 className='new-subtitle'>
            {intl.get('DELIVERED')}
          </h2>
          {fetchingOffersR === 'FETCHED_OFFERS_RENTED' ?
            <Table
              parameters={filteredDataDelivered}
              config={configDelivered}
            />
            :
            <CircularLoading />
          }
        </>
      }
      <div className='mt-3'>
        <Glossary items={STATE['VISIT_STATE_DELIVERS']}></Glossary>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AgentReduxStates) => {
  const { offersRented, offersRentedPending, fetchingOffersR, fetchingOffersRP } = state.offersRented
  return {
    offersRented,
    offersRentedPending,
    fetchingOffersR,
    fetchingOffersRP
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetOffersRented: (state_delivery_voucher: any, filter?: string) => {
      return dispatch(getOffersRented(state_delivery_voucher, filter))
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tables)
