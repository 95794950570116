import { dataHelper, filtersHelper } from "../../helpers/configViewsHelper"

const delivered = {
  filters: filtersHelper,
  type_table: 'delivered',
  label: 'DELIVERS_SEARCH_LABEL',
  placeholder_text: 'UNIT_ADDRESS_PLACEHOLDER_TEXT',
  table: {
    data: dataHelper,
    actions: [
      { type: 'watch', name: 'INVENTORY' }
    ]
  }
}

export default delivered
