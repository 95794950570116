import React from 'react';

// Dots tango for tango carrousel
export const CustomDot = ({ onClick, ...rest }: any) => {
    const { index, active } = rest;
    return (
        <li
            data-index={index}
            className={`react-multi-carousel-dot ${active ? 'react-multi-carousel-dot--active' : ''}`}
        >
            <button aria-label={`Go to slide ${index + 1}`} type='button' onClick={() => onClick()} />
        </li>
    );
};

// buttons tango for tango carrousel
export const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
    const {
        carouselState: { currentSlide },
    } = rest;
    return (
        <div className='carousel-button-group'>
            <button
                type='button'
                className={`react-multiple-carousel__arrow ${
                    currentSlide === 0 ? 'disable' : 'react-multiple-carousel__arrow--left'
                }`}
                onClick={() => previous()}
            />
            <button
                type='button'
                className='react-multiple-carousel__arrow react-multiple-carousel__arrow--right'
                onClick={() => next()}
            />
        </div>
    );
};
