import { filtersHelper, dataHelper } from "../../helpers/configViewsHelper"

const toBeRestored = {
  filters: filtersHelper,
  type_table: 'to_be_restored',
  label: 'RESTITUTIONS_SEARCH_LABEL',
  placeholder_text: 'UNIT_ADDRESS_RENTER_NAMES_PLACEHOLDER_TEXT',
  table: {
    data: dataHelper,
    actions: [
      { type: 'watch', name: 'INVENTORY' },
      { type: 'redirection_conditional' },
    ],
  },
}

export default toBeRestored
