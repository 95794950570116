import { PostValidationState } from '../../../tango'

const initialState: PostValidationState = {
  post: {},
  fetch: 'LOADING',
  savingPost: 'NO_SAVING',
  photos: [],
  photosUploading: [],
  verified: 'no',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_POST_STARTED':
      return {
        ...state,
        fetch: 'LOADING',
      }
    case 'GET_POST_SUCCESS':
      return {
        ...state,
        post: action.payload.data,
        fetch: 'LOADED',
      }
    case 'UPLOAD_PHOTO_STARTED':
      return {
        ...state,
        photosUploading: [...state.photosUploading, action.payload.photoIndex],
      }
    case 'UPLOAD_PHOTO_SUCCESS':
      const { indexPhoto } = action.payload
      const photosUploading = state.photosUploading.filter(
        (value: any, index: number, arr: any[]) => {
          return value !== indexPhoto
        },
      )
      return {
        ...state,
        photosUploading: photosUploading,
      }
    case 'GET_POST_ERROR':
    case 'UPLOAD_PHOTO_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetch: 'ERROR',
      }
    case 'VALIDATE_UNIT_STARTED':
      return {
        ...state,
      }
    case 'VALIDATE_UNIT_SUCCESS':
      return {
        ...state,
        verified: action.payload.data,
      }
    case 'VALIDATE_UNIT_ERROR':
      return {
        ...state,
        error: action.payload.error,
      }
    case 'UPDATE_POST_STARTED':
      return {
        ...state,
        savingPost: 'SAVING',
      }
    case 'UPDATE_POST_SUCCESS':
      return {
        ...state,
        savingPost: 'SAVED',
      }
    case 'UPDATE_POST_ERROR':
      return {
        ...state,
        savingPost: 'SAVE_ERROR',
        error: action.payload.error,
      }

    default:
      return state
  }
}
