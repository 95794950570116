import React from 'react';
import Carousel from 'react-multi-carousel';

type VideosCarouselProps = {
    videos: Array<any>;
};

const VideosCarousel = ({ videos }: VideosCarouselProps) => {
    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024,
            },
            items: 1,
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 300,
            },
            items: 1,
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464,
            },
            items: 1,
        },
    };

    const displayVideo = () => {
        if (videos.length > 1) {
            return (
                <Carousel
                    containerClass='container-with-dots'
                    showDots
                    deviceType='desktop'
                    infinite={false}
                    keyBoardControl={true}
                    ssr
                    swipeable
                    responsive={responsive}
                >
                    {videos.map((video) => (
                        <div key={video.id} className='video-player-container'>
                            <video
                                className='video-player'
                                controls
                                disablePictureInPicture
                                controlsList='nodownload'
                                preload='auto'
                                src={`${video.video_url}#t=0.9`}
                            />
                        </div>
                    ))}
                </Carousel>
            );
        } else {
            return (
                <div className='video-player-container'>
                    {videos.map((video) => (
                        <video
                            key={video.id}
                            className='video-player'
                            controls
                            disablePictureInPicture
                            controlsList='nodownload'
                            preload='auto'
                            src={`${video.video_url}#t=0.9`}
                        />
                    ))}
                </div>
            );
        }
    };

    return <div className='item-carousel-container mx-auto'>{displayVideo()}</div>;
};
export default VideosCarousel;
