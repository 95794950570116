import { toast } from 'react-toastify'
import { deleteFileStart, deleteFileSuccess } from './types'
import intl from 'react-intl-universal'
const deleteFile = (visitId: any) => {
  return (dispatch: any) => {
    dispatch(deleteFileStart(visitId))
    dispatch(deleteFileSuccess(visitId))
    toast(intl.get('DOCUMENT_DELETED'))
  }
}
export default deleteFile
